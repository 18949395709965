import React, { useState, useEffect } from "react";

// Price component renders a price card based on given plan
// Re-usable on Plans and Public Pricing pages
const Price = ({
  plan,
  formatPrice,
  onCreditsPriceChange,
  clickBuyCreditsBtn,
}) => {
  const [localCreditsPrice, setLocalCreditsPrice] = useState(0);
  const [localCreditsQuantity, setLocalCreditsQuantity] = useState(1);
  const [
    localCreditsDisplayQuantity,
    setLocalCreditsDisplayQuantity,
  ] = useState("");

  useEffect(() => {
    // set default credits price to state
    if (!plan.quantities) {
      return;
    }

    plan.quantities.forEach((quantity) => {
      if (!quantity.default) {
        return;
      }

      setLocalCreditsPrice(quantity.totalPrice);
      setLocalCreditsQuantity(quantity.forStripe);
      setLocalCreditsDisplayQuantity(quantity.forDisplay);
      return;
    });
  }, [plan.quantities]);

  const handleCreditsPriceChange = (totalPrice, forStripe, forDisplay) => {
    setLocalCreditsPrice(totalPrice);
    setLocalCreditsQuantity(forStripe);
    setLocalCreditsDisplayQuantity(forDisplay);
    if (onCreditsPriceChange) {
      onCreditsPriceChange(totalPrice, forStripe, forDisplay);
    }
  };

  return (
    <>
      <div className="card-header">
        <h2 className="h3 my-0 font-weight-normal card-title">{plan.name}</h2>
        <br />
        <h3 className="card-subtitle">
          $
          {formatPrice(
            !plan.isMeteredSubscription && localCreditsPrice > 0
              ? localCreditsPrice / localCreditsQuantity
              : plan.price
          )}
          <small className="text-muted">/{plan.paymentCycle}</small>
          {plan.overage && (
            <small className="text-muted">
              {" "}
              +{<br></br>} ${formatPrice(plan.overage, 5)} per extra{" "}
              {plan.usageType}{" "}
            </small>
          )}
        </h3>
      </div>
      <div className="card-body">
        {!plan.isMeteredSubscription &&
          plan.quantities &&
          plan.quantities.map((quantity, i) => (
            <div
              className="form-check text-left mt-2 mb-2"
              key={quantity.totalPrice.toString()}
            >
              <input
                className="form-check-input"
                type="radio"
                name={`flexRadioDefault-${plan.id}`}
                id={`flexRadioDefault-${plan.id}-${i}`}
                defaultChecked={quantity.default || undefined}
                onChange={() => {
                  handleCreditsPriceChange(
                    quantity.totalPrice,
                    quantity.forStripe,
                    quantity.forDisplay
                  );
                  if (clickBuyCreditsBtn) {
                    clickBuyCreditsBtn();
                  }
                }}
                disabled={plan.deprecated}
              />
              <label
                className="form-check-label"
                htmlFor={`flexRadioDefault-${plan.id}-${i}`}
                style={{
                  cursor: "pointer",
                }}
              >
                ${formatPrice(quantity.totalPrice, 2)} - {quantity.forDisplay}
              </label>
            </div>
          ))}
        <ul className="list-unstyled mt-3 mb-4 text-left">
          {plan.features.map((feature) => (
            <li key={feature}>
              <i className="fa fa-check-circle text-success"></i> {feature}
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};

export default Price;
