import React, { useState, useContext, useEffect, useRef } from "react";
import {
  FirebaseAuth,
  CloudFunctions,
} from "../../../../components/FirebaseAuth/firebase";
import { AuthContext } from "../../../../components/FirebaseAuth";
import { BreadcrumbContext } from "../../../../components/Breadcrumb";
import Loader from "../../../../components/Loader";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import Alert from "../../../../components/Alert";
import Price from "../../../../components/Price";
import PriceCTA from "../../../../components/PriceCTA";
import countries from "../../../../inc/country.json";
import { Link } from "react-router-dom";

const Plans = () => {
  const title = "Select a Plan";

  const { userData, authUser } = useContext(AuthContext);
  const stripe = useStripe();
  const elements = useElements();
  const mountedRef = useRef(true);
  const { setBreadcrumb } = useContext(BreadcrumbContext);

  const CARD_ELEMENT_OPTIONS = {
    style: {
      base: {
        color: "#32325d",
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#aab7c4",
        },
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a",
      },
    },
    hidePostalCode: true,
  };

  const [loading, setLoading] = useState(true);
  const [processing, setProcessing] = useState(false);
  const [plans, setPlans] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState({
    id: 0,
  });
  const [currentPlan, setCurrentPlan] = useState({
    id: 0,
  });
  const [cardError, setCardError] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [country, setCountry] = useState("");
  const [countryError, setCountryError] = useState(null);
  const [state, setState] = useState("");
  const [stateError, setStateError] = useState(null);
  const [browserLanguage, setBrowserLanguage] = useState("en-US"); // use for price display
  const [selectedPlanCreditsPrice, setSelectedPlanCreditsPrice] = useState(0); // use for credits pricing plan only
  const [
    selectedPlanCreditsQuantity,
    setSelectedPlanCreditsQuantity,
  ] = useState(1); // use for credits pricing plan only
  const [
    selectedPlanCreditsDisplayQuantity,
    setSelectedPlanCreditsDisplayQuantity,
  ] = useState(""); // use for credits pricing plan only
  const [showBillingDetails, setShowBillingDetails] = useState(false);

  // formatPrice formats a given price as per user's browser language
  const formatPrice = (price, decimalPlaces) => {
    const finalisedDecimalPlaces = decimalPlaces || (price > 10 ? 0 : 2);

    return new Intl.NumberFormat(browserLanguage, {
      minimumFractionDigits: finalisedDecimalPlaces,
      maximumFractionDigits: finalisedDecimalPlaces,
    }).format(price);
  };

  const onCreditsPriceChange = (
    creditsPrice,
    creditsQuantity,
    creditsDisplayQuantity
  ) => {
    setSelectedPlanCreditsPrice(creditsPrice);
    setSelectedPlanCreditsQuantity(creditsQuantity);
    setSelectedPlanCreditsDisplayQuantity(creditsDisplayQuantity);
  };

  const clickBuyCreditsBtn = () => {
    document.getElementById("buy-credits").click();
  };

  const clickPriceCTABtn = (plan) => {
    for (let i = 0; i < plans.length; i++) {
      if (plans[i].id === plan.id) {
        setSelectedPlan(plan);
        // Only set initial credit price state if it hasn't been set yet
        if (
          !selectedPlanCreditsPrice &&
          !plan.isMeteredSubscription &&
          plan.quantities
        ) {
          const defaultQuantity =
            plan.quantities.find((q) => q.default) || plan.quantities[0];
          if (defaultQuantity) {
            setSelectedPlanCreditsPrice(defaultQuantity.totalPrice);
            setSelectedPlanCreditsQuantity(defaultQuantity.forStripe);
            setSelectedPlanCreditsDisplayQuantity(defaultQuantity.forDisplay);
          }
        }
      }
    }

    // scroll to billing details which is not visible otherwise on large screens or mobile
    const divElement = document.getElementById("billing-details");
    divElement.scrollIntoView({
      behavior: "smooth",
    });
  };

  useEffect(() => {
    setBreadcrumb([
      {
        to: "/",
        text: "Home",
        active: false,
      },
      {
        to: "/account/" + userData.currentAccount.id + "/",
        text: userData.currentAccount.name,
        active: false,
      },
      {
        to: null,
        text: title,
        active: true,
      },
    ]);
    setLoading(true);

    const getLang = () =>
      navigator.language ||
      navigator.browserLanguage ||
      (navigator.languages || ["en-US"])[0];
    setBrowserLanguage(getLang());

    const plansQuery = FirebaseAuth.firestore()
      .collection("plans")
      .orderBy("price", "asc");
    plansQuery.get().then((planSnapShots) => {
      if (!mountedRef.current) return null;
      let p = [];
      planSnapShots.forEach((doc) => {
        const plan = {
          id: doc.id,
          name: doc.data().name,
          price: doc.data().price,
          quantities: doc.data().quantities, // for credits based plans only, null otherwise
          isMeteredSubscription: doc.data().isMeteredSubscription,
          usageLimit: doc.data().usageLimit,
          usageType: doc.data().usageType,
          overage: doc.data().overage,
          currency: doc.data().currency,
          paymentCycle: doc.data().paymentCycle,
          features: doc.data().features,
          stripePriceId: doc.data().stripePriceId,
          deprecated: doc.data().deprecated,
          custom: doc.data().custom,
          coupon: doc.data().coupon, // only for custom plans in certain cases, use freetext description
          customAccount:
            doc.data().customAccounts &&
            doc.data().customAccounts.includes(userData.currentAccount.name),
          current:
            userData.currentAccount.subscriptionStatus === "active" &&
            userData.currentAccount.planId === doc.id
              ? true
              : false,
        };
        // only include deprecated plan if it's the user's current plan
        // only include custom plan if the user's account is included in the plan
        if (
          (!plan.deprecated && !plan.custom) ||
          (plan.deprecated && plan.current) ||
          (plan.custom && plan.customAccount)
        ) {
          p.push(plan);
        }
        if (plan.current) {
          setCurrentPlan(plan);
        }
      });
      if (p.length > 0) {
        const ascendingOrderPlans = p.sort(
          (a, b) => parseFloat(a.price) - parseFloat(b.price)
        );

        // order pay as you go plans before subscriptipn plans
        const ascendingNonMeteredPlans = ascendingOrderPlans.sort((a, b) => {
          if (a.isMeteredSubscription === b.isMeteredSubscription) return 0;
          if (a.isMeteredSubscription) return 1;
          return -1;
        });

        // order non-deprecated plans before deprecated current plan
        const ascendingOrderActivePlans = ascendingNonMeteredPlans.sort(
          (a, b) => {
            if (a.deprecated === b.deprecated) return 0;
            if (a.deprecated) return 1;
            return -1;
          }
        );

        setPlans(ascendingOrderActivePlans);
      }

      setLoading(false);
    });
    return () => {
      mountedRef.current = false;
    };
  }, [userData, setBreadcrumb, title]);

  const subscribe = async (event) => {
    event.preventDefault();
    setProcessing(true);
    setErrorMessage(null);

    let hasError = false;
    let paymentMethodId = "";

    if (selectedPlan.price !== 0) {
      if (country === "") {
        setCountryError("Please select a country.");
        hasError = true;
      }

      if (
        state === "" &&
        countries.countries[country] &&
        countries.countries[country].states
      ) {
        setStateError("Please select a state.");
        hasError = true;
      }

      setCardError(null);

      if (!stripe || !elements) {
        // Stripe.js has not loaded yet. Make sure to disable
        // form submission until Stripe.js has loaded.
        return;
      }

      // Get a reference to a mounted CardElement. Elements knows how
      // to find your CardElement because there can only ever be one of
      // each type of element.
      const cardElement = elements.getElement(CardElement);

      // Use your card Element with other Stripe.js APIs
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: "card",
        card: cardElement,
      });

      if (error) {
        setCardError(error.message);
        hasError = true;
      } else {
        paymentMethodId = paymentMethod.id;
      }
    }

    if (!hasError) {
      const createSubscription = CloudFunctions.httpsCallable(
        "createSubscription"
      );
      createSubscription({
        planId: selectedPlan.id,
        quantity: selectedPlanCreditsQuantity, // quantity for stripe
        accountId: userData.currentAccount.id,
        paymentMethodId: paymentMethodId,
        billing: {
          country: country,
          state: state,
        },
      })
        .then((res) => {
          console.log("res object from createSubscription", res);
          if (res.data.actionRequired) {
            // Use Stripe.js to handle required card action (3D Secure)
            return stripe.confirmCardPayment(res.data.clientSecret);
          } else {
            // succeeded, so perform physical page load to reload the account data
            document.location =
              "/account/" +
              userData.currentAccount.id +
              "/?subscription=success";
          }
        })
        .then((res) => {
          if (!res) {
            // following steps only relevant for 3D secure as regular card already succeeded
            return;
          }

          if (res.error) {
            // stripe call failed
            throw res.error;
          }
          // The card action has been handled
          // The PaymentIntent can be confirmed again on the server to update DB with active subscription status
          // Tested webhooks but found it to not be reliable, with delays of several minutes sometimes
          const finalizeAuthenticatedPayment = CloudFunctions.httpsCallable(
            "finalizeAuthenticatedPayment"
          );
          return finalizeAuthenticatedPayment({
            paymentIntentID: res.paymentIntent.id,
            accountId: userData.currentAccount.id,
          });
        })
        .then((res) => {
          if (!res) {
            // following steps only relevant for 3D secure as regular card already succeeded
            return;
          }

          // physical page load to reload the account data
          document.location =
            "/account/" + userData.currentAccount.id + "/?subscription=success";
        })
        .catch((err) => {
          setProcessing(false);
          // scroll to error message which is not visible otherwise on large screens or mobile
          document.getElementById("error-msg").scrollIntoView({
            behavior: "smooth",
          });
          setErrorMessage(err.message);
        });
    } else {
      setProcessing(false);
    }
  };

  return (
    <>
      <div className="container-fluid">
        <div className="animated fadeIn">
          <div className="card-deck mb-3">
            <div className="card">
              <div className="card-header text-center">
                {/* empty div only used for scrolling to error message */}
                <div id="error-msg"></div>
                <h1 className="h2 text-responsive-large">{title}</h1>
                <h2 className="h4 text-responsive-small">
                  Choose the best plan to fit your translation needs. Upgrade
                  with ease as your demands grow, thanks to transparent base and
                  overage pricing.
                </h2>
                <ul className="row col-sm-12 d-flex justify-content-center">
                  <li key="paypal">
                    <i className="c-sidebar-nav-icon fa fa-credit-card"></i>{" "}
                    Paypal, Debit/Credit Cards supported. Payments handled
                    securely by Stripe.
                  </li>
                </ul>
              </div>
              <div className="card-body">
                {userData.currentAccount.owner === authUser.user.uid ? (
                  <>
                    {errorMessage !== null && (
                      <Alert
                        type="danger"
                        message={errorMessage}
                        dismissible={true}
                        onDismiss={() => setErrorMessage(null)}
                      ></Alert>
                    )}
                    {plans.length > 0 ? (
                      <div className="row justify-content-md-center">
                        <div className="col col-sm-12 col-md-12 col-lg-12 col-xl-12">
                          <div className="card-deck mb-5 text-center">
                            {plans.map((plan) => (
                              <div className="card" key={plan.id}>
                                {plan.current &&
                                  plan.deprecated &&
                                  !plan.custom && (
                                    <div className="text-center">
                                      <Alert
                                        type="warning"
                                        message="Deprecated Active Plan. Subscription will still be renewed if applicable."
                                        dismissible={false}
                                      ></Alert>
                                    </div>
                                  )}
                                {plan.current &&
                                  (!plan.deprecated || plan.custom) && (
                                    <div className="text-center">
                                      <Alert
                                        type="success"
                                        message="Active Plan"
                                        dismissible={false}
                                      ></Alert>
                                    </div>
                                  )}
                                <Price
                                  key={plan.id}
                                  plan={plan}
                                  formatPrice={formatPrice}
                                  onCreditsPriceChange={
                                    plan.id === selectedPlan.id
                                      ? onCreditsPriceChange
                                      : undefined
                                  }
                                  clickBuyCreditsBtn={clickBuyCreditsBtn}
                                />
                                <PriceCTA
                                  key={`PriceCTA-${plan.id}`}
                                  plan={plan}
                                  selectedPlan={selectedPlan}
                                  onclickHandler={clickPriceCTABtn}
                                  processing={processing}
                                />
                              </div>
                            ))}
                            <div className="card" key="enterprise-card">
                              <div className="card-header">
                                <h2 className="h3 my-0 font-weight-normal card-title">
                                  Enterprise
                                </h2>
                                <br />
                                <h5 className="card-subtitle text-muted">
                                  Design a custom plan for your business or
                                  license our API for on-premise deployment
                                </h5>
                              </div>
                              <div className="card-body">
                                <ul className="list-unstyled mt-3 mb-4 text-left">
                                  <br></br>
                                  <li key="enterprise-unlimited">
                                    <i className="fa fa-check-circle text-success"></i>{" "}
                                    Unlimited translated characters and parallel
                                    requests
                                  </li>
                                  <br></br>
                                  <li key="enterprise-support">
                                    <i className="fa fa-check-circle text-success"></i>{" "}
                                    Dedicated customer support
                                  </li>
                                  <br></br>
                                  <li key="enterprise-discount">
                                    <i className="fa fa-check-circle text-success"></i>{" "}
                                    Volume discounts
                                  </li>
                                  <br></br>
                                  <li key="enterprise-payment">
                                    <i className="fa fa-check-circle text-success"></i>{" "}
                                    Custom payment methods such as Bank
                                    Transfers
                                  </li>
                                  <br></br>
                                  <li key="enterprise-licensing">
                                    <i className="fa fa-check-circle text-success"></i>{" "}
                                    License for deployment on-premise or in your
                                    own Cloud environment
                                  </li>
                                </ul>
                              </div>
                              <div className="card-footer bg-white">
                                <a
                                  href="mailto:%73%75%70%70%6f%72%74%40%6c%65%63%74%6f%2e%61%69"
                                  className="btn btn-block btn-outline-success"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  Contact Us
                                </a>
                              </div>
                            </div>
                          </div>

                          <div
                            className="card-deck"
                            key="billing-visibility"
                            style={{
                              visibility:
                                selectedPlan.id !== 0 && selectedPlan.price > 0
                                  ? "visible"
                                  : "hidden",
                            }}
                          >
                            <div className="card mb-4" key="billing-details">
                              <div className="card-header text-center">
                                <h3>Billing Details</h3>
                              </div>
                              {currentPlan.id !== 0 &&
                                !currentPlan.isMeteredSubscription &&
                                selectedPlan.isMeteredSubscription && (
                                  <div className="text-center">
                                    <Alert
                                      type="warning"
                                      message="You're upgrading from a Pay as you go plan to a Subscription plan. Existing credits will not be carried over."
                                      dismissible={false}
                                    ></Alert>
                                  </div>
                                )}
                              {currentPlan.isMeteredSubscription &&
                                !selectedPlan.isMeteredSubscription && (
                                  <div className="text-center">
                                    <Alert
                                      type="warning"
                                      message="You're switching from a Subscription plan to a Pay as you go plan. Your existing subscription plan will be canceled."
                                      dismissible={false}
                                    ></Alert>
                                  </div>
                                )}
                              <div className="card-body">
                                <div className="form-group row">
                                  <label className="col-lg-3 col-form-label mt-2 text-lg-right">
                                    <b>Plan Summary</b>
                                  </label>
                                  <div className="col-lg-9 mt-2">
                                    <ul className="list-unstyled">
                                      {selectedPlan.isMeteredSubscription && (
                                        <>
                                          <li key={selectedPlan.name}>
                                            <i className="fa fa-check-circle text-success"></i>{" "}
                                            {`${selectedPlan.name} subscription plan`}
                                          </li>
                                          <li key={selectedPlan.price}>
                                            <i className="fa fa-check-circle text-success"></i>{" "}
                                            <strong>
                                              ${formatPrice(selectedPlan.price)}
                                            </strong>
                                            /{selectedPlan.paymentCycle} +{" "}
                                            {selectedPlan.overage &&
                                              `${formatPrice(
                                                selectedPlan.overage,
                                                5
                                              )} per extra translated ${
                                                selectedPlan.usageType
                                              } `}
                                          </li>
                                          <li key="validity">
                                            <i className="fa fa-check-circle text-success"></i>{" "}
                                            Subscription plan renews{" "}
                                            {selectedPlan.paymentCycle}ly from
                                            the date of subscription
                                          </li>
                                          {selectedPlan.coupon && (
                                            <li key="coupon">
                                              <i className="fa fa-check-circle text-success"></i>{" "}
                                              Promotion applied:{" "}
                                              <strong>
                                                {
                                                  selectedPlan.coupon
                                                    .description
                                                }
                                              </strong>
                                            </li>
                                          )}
                                        </>
                                      )}
                                      {!selectedPlan.isMeteredSubscription && (
                                        <>
                                          <li key={selectedPlan.name}>
                                            <i className="fa fa-check-circle text-success"></i>{" "}
                                            {`${selectedPlan.name} plan`}
                                          </li>
                                          <li key={selectedPlan.price}>
                                            <i className="fa fa-check-circle text-success"></i>{" "}
                                            <strong>
                                              $
                                              {formatPrice(
                                                selectedPlanCreditsPrice > 0
                                                  ? selectedPlanCreditsPrice
                                                  : selectedPlan.price,
                                                2
                                              )}
                                            </strong>{" "}
                                            - One time payment
                                          </li>
                                          <li key={selectedPlanCreditsQuantity}>
                                            <i className="fa fa-check-circle text-success"></i>{" "}
                                            Credits worth{" "}
                                            {selectedPlanCreditsDisplayQuantity}{" "}
                                            (Effective price: $
                                            <strong>
                                              {formatPrice(
                                                selectedPlanCreditsPrice > 0
                                                  ? selectedPlanCreditsPrice /
                                                      selectedPlanCreditsQuantity
                                                  : selectedPlan.price,
                                                2
                                              )}
                                              /{selectedPlan.paymentCycle})
                                            </strong>
                                          </li>
                                          <li key="validity">
                                            <i className="fa fa-check-circle text-success"></i>{" "}
                                            Credits available for use anytime
                                            within 1 year of purchase. Unused
                                            credits roll over when you Top up
                                            within 1 year of purchase
                                          </li>
                                        </>
                                      )}
                                      <li key="support">
                                        <i className="fa fa-check-circle text-success"></i>{" "}
                                        <strong>Paypal</strong> preferred
                                        instead or have other questions? Contact
                                        us via{" "}
                                        <a
                                          href={`https://go.crisp.chat/chat/embed/?website_id=${window.CRISP_WEBSITE_ID}&&user_email=${userData.currentAccount.name}`}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                        >
                                          Live Chat
                                        </a>
                                        ,{" "}
                                        <a
                                          href="mailto:%73%75%70%70%6f%72%74%40%6c%65%63%74%6f%2e%61%69"
                                          target="_blank"
                                          rel="noopener noreferrer"
                                        >
                                          Email
                                        </a>{" "}
                                        or{" "}
                                        <a
                                          href="https://t.me/lectoai/"
                                          target="_blank"
                                          rel="noopener noreferrer"
                                        >
                                          Telegram
                                        </a>{" "}
                                        and we'll set you up with a Custom Plan
                                        ASAP.
                                      </li>
                                      <li key="paypal" className="ml-3 mt-3">
                                        <i className="c-sidebar-nav-icon fa fa-credit-card"></i>{" "}
                                        Paypal, Debit/Credit Cards supported.
                                        Payments handled securely by Stripe.
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                                <div className="form-group row">
                                  <label className="col-lg-3 col-form-label mt-2 text-lg-right">
                                    <b>Country/Territory</b>
                                  </label>
                                  <div className="col-lg-9 mt-2">
                                    {countryError !== null && (
                                      <Alert
                                        type="danger"
                                        message={countryError}
                                        dismissible={true}
                                        onDismiss={() => setCountryError(null)}
                                      ></Alert>
                                    )}
                                    <select
                                      className="form-control"
                                      defaultValue={country}
                                      onChange={(e) => {
                                        const countryCode =
                                          e.target.selectedOptions[0].value;
                                        setCountry(countryCode);
                                        setState("");
                                        setCountryError(null);
                                      }}
                                    >
                                      <option value="">
                                        -- Select a country --
                                      </option>
                                      {Object.keys(countries.countries).map(
                                        (countryCode) => (
                                          <option
                                            value={countryCode}
                                            key={countryCode}
                                          >
                                            {
                                              countries.countries[countryCode]
                                                .name
                                            }
                                          </option>
                                        )
                                      )}
                                    </select>
                                  </div>
                                </div>
                                {countries.countries[country] &&
                                  countries.countries[country].states && (
                                    <div className="form-group row">
                                      <label className="col-lg-3 col-form-label mt-2 text-lg-right">
                                        <b>State/Province</b>
                                      </label>
                                      <div className="col-lg-9 mt-2">
                                        {stateError !== null && (
                                          <Alert
                                            type="danger"
                                            message={stateError}
                                            dismissible={true}
                                            onDismiss={() =>
                                              setStateError(null)
                                            }
                                          ></Alert>
                                        )}
                                        <select
                                          className="form-control"
                                          defaultValue={state}
                                          onChange={(e) => {
                                            setState(
                                              e.target.selectedOptions[0].value
                                            );
                                            setStateError(null);
                                          }}
                                        >
                                          <option value="">
                                            -- Select a state --
                                          </option>
                                          {Object.keys(
                                            countries.countries[country].states
                                          ).map((stateCode) => (
                                            <option
                                              value={stateCode}
                                              key={stateCode}
                                            >
                                              {
                                                countries.countries[country]
                                                  .states[stateCode]
                                              }
                                            </option>
                                          ))}
                                        </select>
                                      </div>
                                    </div>
                                  )}
                                <div className="form-group row mb-0">
                                  <label className="col-lg-3 col-form-label mt-2 text-lg-right">
                                    <b>Debit/Credit Card</b>
                                  </label>
                                  <div className="col-lg-9 mt-2">
                                    {cardError !== null && (
                                      <Alert
                                        type="danger"
                                        message={cardError}
                                        dismissible={true}
                                        onDismiss={() => setCardError(null)}
                                      ></Alert>
                                    )}
                                    <div className="form-control">
                                      <CardElement
                                        options={CARD_ELEMENT_OPTIONS}
                                      ></CardElement>
                                    </div>
                                  </div>
                                </div>
                                <div className="form-group row mb-0"></div>
                              </div>
                            </div>
                          </div>
                          {selectedPlan.id !== 0 && (
                            <>
                              <div className="form-group mb-3">
                                <p className="text-muted">
                                  By clicking Subscribe, you agree to our{" "}
                                  <a
                                    href="/terms"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    Terms of Service
                                  </a>
                                </p>
                              </div>
                              <button
                                className="btn btn-lg btn-block btn-primary"
                                disabled={
                                  selectedPlan.id === 0 || processing
                                    ? true
                                    : false
                                }
                                onClick={(e) => {
                                  subscribe(e);
                                }}
                              >
                                {processing ? (
                                  <Loader text="Please wait while your subscription is being processed..."></Loader>
                                ) : (
                                  <>Subscribe</>
                                )}
                              </button>
                            </>
                          )}
                          {/* empty div only used for scrolling to billing details */}
                          <div
                            id="billing-details"
                            className="billing-details"
                            key={`billing-details-${showBillingDetails}`}
                          ></div>
                        </div>
                      </div>
                    ) : (
                      <>
                        {loading ? (
                          <Loader text="loading plans..."></Loader>
                        ) : (
                          <div>No plan is found</div>
                        )}
                      </>
                    )}
                  </>
                ) : (
                  <Alert
                    type="danger"
                    message="Access Denied."
                    dismissible={false}
                  ></Alert>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Plans;
