import { FirebaseAuth } from "../../components/FirebaseAuth/firebase";
import { log, SIGN_IN, SIGN_OUT, UPDATE_USERNAME } from "../log";
import * as FingerprintJS from "@fingerprintjs/fingerprintjs";

export const userSignIn = (callback) => {
  var dt = new Date();
  const Firestore = FirebaseAuth.firestore();
  const currentUser = FirebaseAuth.auth().currentUser;

  if (!currentUser) {
    callback(false);
    return;
  }

  const userDocRef = Firestore.collection("users").doc(currentUser.uid);

  // First check if user exists
  userDocRef
    .get()
    .then((doc) => {
      const userData = {
        displayName: currentUser.displayName || null,
        photoURL: currentUser.photoURL || null,
        email: currentUser.email || null,
        emailVerified: currentUser.emailVerified || false,
        lastLoginTime: dt,
      };

      if (doc.exists) {
        // update user document
        userDocRef
          .set(userData, { merge: true })
          .then(() => {
            callback(true);
          })
          .catch((err) => {
            console.error("Error updating user document:", err);
            callback(false);
          });
      } else {
        // create user document with creation time
        userDocRef
          .set({
            ...userData,
            creationTime: dt,
          })
          .then(() => {
            callback(true);
          })
          .catch((err) => {
            console.error("Error creating user document:", err);
            callback(false);
          });
      }
    })
    .catch((err) => {
      console.error("Error checking user document:", err);
      callback(false);
    });

  log(SIGN_IN);
};

export const userSignOut = () => {
  log(SIGN_OUT, (result) => {
    // wait for log is successfully written before signing out
    if (result) {
      FirebaseAuth.auth().signOut();
      // clear any localStorage after signout for user privacy
      localStorage.clear();
    }
  });
};

export const userUpdateName = () => {
  const Firestore = FirebaseAuth.firestore();
  const currentUser = FirebaseAuth.auth().currentUser;

  const userDocRef = Firestore.collection("users").doc(currentUser.uid);
  userDocRef.set(
    {
      displayName: currentUser.displayName,
    },
    { merge: true }
  );
  log(UPDATE_USERNAME);
};

// userVerifyEmail checks for disposable email
export const userVerifyEmail = async (email) => {
  try {
    const response = await fetchWithTimeout(
      "https://disposable.debounce.io/?email=" + email
    );
    const jsonResponse = await response.json();
    if (jsonResponse.disposable === "true") {
      return "fail";
    }
    return "pass";
  } catch (error) {
    console.log("userVerifyEmail failed with error", error);
    return "pass";
  }
};

// fetchWithTimeout fetches given resource with timeout (default 8s)
async function fetchWithTimeout(resource, options = {}) {
  const { timeout = 8000 } = options;

  const controller = new AbortController();
  const id = setTimeout(() => controller.abort(), timeout);
  const response = await fetch(resource, {
    ...options,
    signal: controller.signal,
  });
  clearTimeout(id);
  return response;
}

// getFingerprint returns the unique visitor id per browser
export const getFingerprint = async () => {
  const fpPromise = await FingerprintJS.load();
  const result = await fpPromise.get();
  const visitorId = result.visitorId;

  return visitorId;
};

export const getIP = async () => {
  try {
    const response = await fetchWithTimeout("https://icanhazip.com/");
    const ip = await response.text();

    return ip.trim(); // remove trailing newline character
  } catch (error) {
    console.log("getIP failed with error", error);
    return null;
  }
};
